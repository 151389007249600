import './carousel-banner.scss'
import Carousel from '../../ui/Carousel'
import { mockImages } from './mockImages'
import DynamicImages from './DynamicImage'

interface ICarouselBanner {
  banners: BannerImagesData[]
}

function CarouselBanner({ banners }: ICarouselBanner) {
  const allImages = banners ?? mockImages

  return (
    <Carousel
      infiniteMode
      controls="complete"
      timeout={8000}
      transition={{
        duration: 600,
        property: 'transform',
      }}
    >
      {allImages.map((image, idx) => (
        <>
          <div className="hidden-mobile-banner" key={`desktop-${idx}`}>
            <DynamicImages
              imageValues={{
                href: image.sources[0].srcSet,
                title: image.alt,
                id: `desktop-home-banner-${idx}`,
                link: image.href,
              }}
              imageWidth={{
                desktop: 1903,
                mobile: 375,
              }}
              imageHeight={{
                desktop: 299,
                mobile: 233,
              }}
            />
          </div>

          <div className="display-mobile-banner" key={`mobile-${idx}`}>
            <DynamicImages
              imageValues={{
                href: image.sources[1].srcSet,
                title: image.alt,
                id: `mobile-home-banner-${idx}`,
                link: image.href,
              }}
              imageWidth={{
                desktop: 1903,
                mobile: 375,
              }}
              imageHeight={{
                desktop: 299,
                mobile: 233,
              }}
            />
          </div>
        </>
      ))}
    </Carousel>
  )
}

export default CarouselBanner

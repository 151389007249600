export const mockImages = [
  {
    sources: [
      {
        srcSet: 'https://tatauyqa.vtexassets.com/arquivos/banner-home-tata.png',
        media: '(min-width: 40em)',
      },
      {
        srcSet:
          'https://tatauyqa.vtexassets.com/arquivos/banner-tata-mobile.png',
        media: '(min-width: 40em)',
      },
    ],
    href: '/',
    alt: 'Image not found',
  },
  {
    sources: [
      {
        srcSet: 'https://tatauyqa.vtexassets.com/arquivos/banner-home-tata.png',
        media: '(min-width: 40em)',
      },
      {
        srcSet:
          'https://tatauyqa.vtexassets.com/arquivos/banner-tata-mobile.png',
        media: '(min-width: 40em)',
      },
    ],
    href: '/',
    alt: 'Banner not found',
  },
]

import type { SiteSiteMetadata } from '@generated/graphql'
import { graphql, useStaticQuery } from 'gatsby'

export const useCmsLpbombazosQuery = () => {
  const data = useStaticQuery(
    graphql`
      query LandingPageBobazosQuery {
        site {
          siteMetadata {
            titleTemplate
            title
            description
            siteUrl
          }
        }
        allCmsInstitutionalPage(
          filter: {
            seo: { siteMetadataWithSlug: { slug: { eq: "/bombazos" } } }
          }
        ) {
          edges {
            node {
              seo {
                siteMetadataWithSlug {
                  slug
                }
              }
              name
              sections {
                data
              }
            }
          }
        }
      }
    `
  )

  const site: SiteSiteMetadata = data?.site.siteMetadata
  const bannerImages: BannerImagesData[] =
    data?.allCmsInstitutionalPage?.edges[0]?.node?.sections?.[0]?.data?.allItems

  const collectionTitle =
    data?.allCmsInstitutionalPage?.edges[0]?.node?.sections?.[1]?.data?.content

  const collectionID =
    data?.allCmsInstitutionalPage?.edges[0]?.node?.sections?.[2]?.data
      ?.CollectionID

  const cmsDataCollection =
    data?.allCmsInstitutionalPage?.edges[0]?.node?.sections?.[3]?.data

  return {
    data,
    site,
    bannerImages,
    collectionTitle,
    collectionID,
    cmsDataCollection,
  }
}
